@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  user-select: text;
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
  color: unset;
}


.gradient-text {
  background: linear-gradient(to right, #676166, #e861c2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
